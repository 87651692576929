
import ErrorPage from '@/components/collections/error/ErrorPage.vue'
import ErrorPageDetails from '@/components/collections/error/ErrorPageDetails.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ErrorPage,
    ErrorPageDetails
  }
})
export default class InternalServerError extends Vue {

}
